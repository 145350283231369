<app-page-title [pageTitle]="pageTitle"></app-page-title>
<div id="result">
  <section id="items">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-6 text-center" (click)="SetUserMode(EmployeeType.Maintenance)">
          <a style="text-decoration: none" id="locations-of-cars">
            <img src="assets/images/rent_icon3.png" alt="תחזוקה" />
            <h4>תחזןקה</h4>
          </a>
        </div>
        <div class="col-xs-6 text-center" (click)="SetUserMode(EmployeeType.Wash)">
          <a style="text-decoration: none" id="locations-of-cars">
            <img src="assets/images/rent_icon7.png" alt="שטיפה" />
            <h4>שטיפה</h4>
          </a>
        </div>
      </div>
    </div>
  </section>
</div>
