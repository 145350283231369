import {
  Component,
  OnInit,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { GlobalService } from '../../services/global.service';
import { DomSanitizer } from '@angular/platform-browser';
import { IrregularOrderService } from '../../services/irregular-order.service';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from '../../services/url.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { CarInParking, timeSlot } from '../../models/car';
import { ErrorResponse } from '../../models/error-response';
import { MapLocation } from '../../models/map-location';
import { EmployeeOrder, OfficeOrderTypeDescriptionHeEnum, Order } from '../../models/order';
import { Parking } from '../../models/parking';
import { AlertService } from '../../services/alert.service';
import { GoogleMapsService } from '../../services/google-maps.service';
import { HttpService } from '../../services/http.service';
import { LoadingService } from '../../services/loading.service';
import { OrderService } from '../../services/order.service';
import { ParkingsService } from '../../services/parkings.service';
import { AppGlobals } from '../../shared/app-globals/app-globals';
import { newDate } from '../../shared/function';
import {
  OrderModalContext,
} from '../base-modal/base-modal.component';
import { EmployeeType, UserService } from '../../services/user.service';
import { FileHolder } from 'angular2-image-upload';
import { ReplaySessionService } from '../../services/replay-session.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-car-in-rent',
  templateUrl: './car-in-rent.component.html',
  styleUrls: ['./car-in-rent.component.css'],
})
export class CarInRentComponent
  implements OnInit {
  public OfficeOrderTypeDescriptionHeEnum: typeof OfficeOrderTypeDescriptionHeEnum = OfficeOrderTypeDescriptionHeEnum;

  TravelMode = google.maps.TravelMode;
  _finishOrder = false;
  lat = 32.0535083;
  lng = 34.9595936;
  zoom = 14;

  dir = {
    origin: { lat: 24.799448, lng: 120.979021 },
    destination: { lat: 24.799524, lng: 120.975017 },
  };

  renderOpts: google.maps.DirectionsRendererOptions = {
    suppressMarkers: true,
    markerOptions: <google.maps.MarkerOptions>{
      icon: AppGlobals.POINTER_IMAGES.CITY_CAR,
    },
  };
  f: google.maps.MarkerOptions = <google.maps.MarkerOptions>{};
  markerOpts: {
    origin: google.maps.MarkerOptions,
    destination: google.maps.MarkerOptions,
  } = {
      origin: <google.maps.MarkerOptions>{
        icon: AppGlobals.POINTER_IMAGES.MAN_WALKING,
        opacity: 0.8,
      },
      destination: <google.maps.MarkerOptions>{
        icon: AppGlobals.POINTER_IMAGES.CITY_CAR,
        opacity: 0.8,
      },
    };

  context: OrderModalContext;

  addressName: string;

  isShowExtendRent = false;
  isShowMapWithDirection = false;
  isShowLateReport = false;
  isShowFinishRent = false;
  isShowMaintenance = false;
  isShowSendPictures = false;

  isIrregularOrder = false;

  enableOpenDoors = true;
  enableOpenGate = true;

  pictures: Picture[] = [];

  images: string[] = [];
  imagesSent = false;
  selectAllVal: any;
  _maintenanceNotes: string;

  isOpenningMap = false;
  _maintenanceForm: FormGroup;
  _arrayItems: {
    item: string;
    id: number;
    value: number;
  }[] = [];
  enableGetUnlockCode = true;

  get maxImagesSize(): number {
    return AppGlobals.IMAGES_DATA.MAX_SIZE_KB.REGISTER;
  }

  get isAllTabsClose(): boolean {
    return (
      !this.isShowMapWithDirection &&
      !this.isShowLateReport &&
      !this.isShowExtendRent &&
      !this.isShowFinishRent &&
      !this.isShowSendPictures &&
      !this.isShowMaintenance
    );
  }

  get showMaintenanceOptions(): boolean {
    return (
      this._userService.userMode === EmployeeType.Maintenance
    );
  }

  get showWashOptions(): boolean {
    return (
      this._userService.userMode === EmployeeType.Wash
    );
  }

  hoursOrDays: number;
  daysToAdd = 0;
  hoursToAdd = 0;
  minutesToAdd = 0;
  lateMinutes = 1;
  getCode = 'GET_CODE';
  timer: number = null;

  startCatchedTime: Date;

  get isExtendByMinutes(): boolean {
    return (
      this.order.endTime.getTime() +
      AppGlobals.TIMES.HOURS_IN_TIME * this.hoursToAdd != this.newOrderEndTime.getTime()
    );
  }

  get newOrderEndTime(): Date {
    if (this.daysToAdd > 0) {
      console.log(this.daysToAdd);
      console.log(
        this.order.endTime.getTime() +
        this.daysToAdd * 24 * AppGlobals.TIMES.HOURS_IN_TIME
      );
      return new Date(
        this.order.endTime.getTime() +
        this.daysToAdd * 24 * AppGlobals.TIMES.HOURS_IN_TIME
      );
    }

    if (this.startCatchedTime) {
      return this.startCatchedTime;
    }

    if (this.minutesToAdd > 0) {
      return new Date(
        this.order.endTime.getTime() +
        this.minutesToAdd * AppGlobals.TIMES.MINUTES_IN_TIME
      );
    } else {
      return new Date(
        this.order.endTime.getTime() +
        this.hoursToAdd * AppGlobals.TIMES.HOURS_IN_TIME
      );
    }
  }

  get CARS_IMAGES() {
    return AppGlobals.CARS_IMAGES;
  }

  get mapsDirectionToCarLink(): string {
    return (
      'geo:0,0?q=' +
      this.order.lastCarLocation.lat +
      ',' +
      this.order.lastCarLocation.lon +
      '(כאן נמצא הרכב שלך)&travelmode=walking'
    );
  }

  get DomSanitizer(): DomSanitizer {
    return this.domSanitizer;
  }

  get customStyle() {
    return AppGlobals.STYLES.IMAGE_UPLOAD;
  }

  getCarsImages(carType: string) {
    return AppGlobals.CARS_IMAGES[carType]
      ? AppGlobals.CARS_IMAGES[carType]
      : AppGlobals.CARS_IMAGES.NO_DATA;
  }

  constructor(
    private _parkingsService: ParkingsService,
    private _googleMapsService: GoogleMapsService,
    private _orderService: OrderService,
    private _alertService: AlertService,
    private _loadingService: LoadingService,
    private _gloabalService: GlobalService,
    private _irregularOrder: IrregularOrderService,
    private translate: TranslateService,
    private _datePipe: DatePipe,
    private domSanitizer: DomSanitizer,
    private httpService: HttpService,
    private _urlService: UrlService,
    private _userService: UserService,
    private _globalService: GlobalService,
    private _formBuilder: FormBuilder,
    private replaySessionService: ReplaySessionService,
    private activeModal: NgbActiveModal,
    public order: Order,
  ) {
    this._maintenanceForm = this._formBuilder.group({
      Array: this._formBuilder.array([]),
    });

    console.log('this.order', this.order);
  }

  async ngOnInit() {
    console.log(this.order.period);

    this.addressName = this.order.startParking
      ? this.order.startParking.name
      : null;
    const items = await this._parkingsService.checkListItems;
    for (let index = 0; index < items.length; index++) {
      this._arrayItems.push(<any>{ id: index, item: items[index] });
    }
    console.log(this._arrayItems);
  }

  async getUnlockCode() {
    this.enableGetUnlockCode = false;
    try {
      const unlockCode = await this._orderService.getActiveUnlockCode(this.order.id);
      this.enableGetUnlockCode = true;
      this.replaySessionService.sendEventToReplaySession('unlockCodeResponse', { unlockCode });
      if (unlockCode) {
        this.getCode = unlockCode.toString();
      } else {
        this.getCode = this.translate.currentLang === 'he' ? 'נסה שנית' : 'Try again';
      }
      setTimeout(() => {
        this.getCode = this.translate.currentLang === 'he'
          ? 'קבל את קוד הרכב'
          : 'Get the code';
      }, 30000);
    } catch (err: any) {
      this.enableGetUnlockCode = true;
      this.replaySessionService.sendErrorToReplaySession(err);
      this.replaySessionService.sendEventToReplaySession('unlockCodeError', err);
      this._alertService.error(err?.CustomErrorMessage);
    } finally {
      this.enableGetUnlockCode = true;
    }
  }

  finishRent(isWashed?: boolean) {
    this._orderService
      .orderIsDoneReport(this.order.id, isWashed)
      .then(() => {
        this._alertService.success('תודה ולהתראות!');
        setTimeout(() => {
          this.activeModal.close(true);
        }, 1500);
      })
      .catch((error) => {
        console.error(error);
        this._alertService.error(error.error.message);
      });
  }

  toggleShowMapWithDirection() {
    if (!this.isOpenningMap) {
      this.isOpenningMap = true;
      this._loadingService.startLoading();
      this._googleMapsService.getCurrentPosition().subscribe(
        (location: MapLocation) => {
          this.dir.origin.lat = location.lat;
          this.dir.origin.lng = location.lon;
          this.markerOpts.origin.visible = true;

          displayDirection();
          this.isOpenningMap = false;
        },
        () => {
          this.isOpenningMap = false;
          this._alertService.error('לא הצלחנו למצוא את מיקומך');
          this.markerOpts.origin.visible = false;
          displayDirection();
          this.dir.origin = this.dir.destination;
        }
      );
    }

    const displayDirection = () => {
      console.log(this.order.lastCarLocation);
      if (this.order.lastCarLocation) {
        this.closeAllTabs();
        this._loadingService.stopLoading();
        this.isShowMapWithDirection = !this.isShowMapWithDirection;

        this.dir.destination.lat = this.order.lastCarLocation.lat;
        this.dir.destination.lng = this.order.lastCarLocation.lon;
      }
    };
  }
  toggleShowLateReport() {
    this.closeAllTabs();
    this.isShowLateReport = !this.isShowLateReport;
  }
  toggleFinishRent() {
    this.closeAllTabs();
    this.isShowFinishRent = !this.isShowFinishRent;
  }
  toggleSendPictures() {
    this.closeAllTabs();
    this.isShowSendPictures = !this.isShowSendPictures;
  }

  toggleMaintenance() {
    this.closeAllTabs();
    this.isShowMaintenance = !this.isShowMaintenance;
  }

  closeAllTabs() {
    this.isShowMapWithDirection = false;
    this.isShowLateReport = false;
    this.isShowExtendRent = false;
    this.isShowFinishRent = false;
    this.isShowSendPictures = false;
    this.isShowMaintenance = false;
  }

  extendRentHoursOrDays(hoursOrDays) {
    this.hoursOrDays = hoursOrDays;
    if (hoursOrDays === 1) {
      this.hoursToAdd = 1;
      this.daysToAdd = 0;
    } else if (hoursOrDays === 2) {
      this.hoursToAdd = 0;
      this.daysToAdd = 1;
    }
  }

  extendRent(minutes: number = null) {
    // set the checket time slot to the order in the order service in order to check it:
    this._orderService.changeCurrentOrder(
      this._gloabalService.copyObject(this.order)
    );
    // add hours to the order's time:
    this.newOrderEndTime.setSeconds(0);
    this.order.endTime.setSeconds(0);
    this._orderService.EndTime = this.newOrderEndTime;
    this._orderService.StartTime = this.order.endTime;
    this._loadingService.startLoading();

    this._parkingsService
      .getParkingsWithCarsAndTimeSlots([this.order.startParking.id], false)
      .subscribe(
        (parkings: Parking[]) => {
          const parking: Parking = parkings.find(
            (p) => p.id == this.order.startParking.id
          );
          let car: CarInParking;
          this._loadingService.startLoading();
          console.log('parking', parking);

          // if parking is available to those hours:
          if (
            (car = parking.carsDetails.find(
              (c) => c.carNumber == this.order.car.carNumber
            )) &&
            car.availability == 1
          ) {
            const editedOrder = Object.assign({}, this.order);
            editedOrder.endTime = this.newOrderEndTime;
            this._orderService
              .updateOrder(true, editedOrder, false, this.isIrregularOrder)
              .then((order: EmployeeOrder | Order) => {
                this._loadingService.stopLoading();
                this._alertService.success(
                  'הזמנתך הוארכה בהצלחה! נא החזר את הרכב לתחנה עד ל:' +
                  this._datePipe.transform(
                    order.endTime,
                    'dd/MM/yyyy - HH:mm'
                  )
                );
                this.isShowExtendRent = false;
                this.startCatchedTime = null;
                this.order = order;
              })
              .catch((error: ErrorResponse) => {
                this._loadingService.stopLoading();
                console.error(error);
                this._alertService.error(error.CustomErrorMessage);
              });
          } else if (car && car.availability == 2) {
            const firstCatchedTimeSlot: timeSlot = car.timeSlots.find(
              (t) => newDate(t.start).getTime() > this.order.endTime.getTime()
            );
            const newStart = newDate(firstCatchedTimeSlot.start);
            newStart.setSeconds(this.order.endTime.getSeconds());
            // this.startCatchedTime = newDate(firstCatchedTimeSlot.start);
            this.hoursToAdd = Math.ceil(
              (newStart.getTime() - this.order.endTime.getTime()) /
              AppGlobals.TIMES.HOURS_IN_TIME
            );

            const isTenMinuteBefore = this._irregularOrder.isTenMinuteBefore(
              this.order.endTime,
              newStart
            );
            if (isTenMinuteBefore) {
              this.startCatchedTime = newDate(firstCatchedTimeSlot.start);
              this.isIrregularOrder = true;
              this.newOrderEndTime.setSeconds(this.order.endTime.getSeconds());
            } else {
              this._alertService.error(
                ' לא ניתן להאריך את זמן השכירות, באפשרותך לדווח על איחור במספר דקות'
              );
              this._loadingService.stopLoading();
            }
          }
        },
        (error: ErrorResponse) => {
          this._loadingService.stopLoading();
          console.error(error);
          this._alertService.error(error.CustomErrorMessage);
        }
      );
  }

  removeFromHoursToAdd() {
    if (this.hoursToAdd > 1) {
      this.hoursToAdd--;
    }
  }

  addToHoursToAdd() {
    this.hoursToAdd++;
    console.log(this.hoursToAdd);
  }

  removeFromDaysToAdd() {
    if (this.daysToAdd > 1) {
      this.daysToAdd--;
      console.log(this.daysToAdd);
    }
  }

  addToDaysToAdd() {
    this.daysToAdd++;
    console.log(this.daysToAdd);
  }

  addMinutesToRent(minutes: number) {
    this.minutesToAdd = minutes;
    this.extendRent(minutes);
  }

  sendImage() {
    try {
      const files = [];
      const order = this.order;

      this.images.forEach((image, index) => {
        const type = image.substring('data:'.length, image.indexOf(';base64'));
        files.push({
          data: image.split(',')[1],
          originalname: `image_${index}_${order.id}`,
          mimetype: type,
        });
      });

      const body = {
        orderId: order.id.toString(),
        carNumber: order.car.carNumber,
        orderType: this.order['officeDriveType'],
        uploadType: 'base64',
        files: files,
      };

      if (this.images.length < 8) {
        this._alertService.error('חובה להעלות 8 תמונות שטיפה');
        return;
      }

      this.httpService.post(this._urlService.nodeUrl() + 'api/files/add', body)
        .then(res => {
          console.log(res);
          this._alertService.success('פעולתך בוצעה');
          this.pictures = [];
        })
        .catch(err => {
          console.log(err);
          this._alertService.error(err.CustomErrorMessage.value);
        });
    } catch (error: any) {
      this._alertService.error(error);
    }
  }

  onUploadFinished(event: FileHolder, isMustPicture: string = null) {
    console.log(event);
    console.log(isMustPicture);
    this.images.push(event.src);
  }

  onRemoved(event: FileHolder) {
    this.images = [];
  }

  finishcarMaintenance() {
    this._arrayItems.forEach((i) => {
      if (i.value === null) {
        i.value = 3;
      }
    });

    const body = {
      UserId: this._userService.userPermission.email,
      CarId: this.order.car.carNumber,
      OrderId: this.order.id,
      CheckList: this._arrayItems,
      CarType: this.order.car.carType,
      Notes: this._maintenanceNotes,
      ShouldFinish: this._finishOrder,
    };

    this.httpService.post(
      this._urlService.nodeUrl() + 'cars/maintenance/add',
      body
    )
      .then(async (res) => {
        console.log(res);
        this._alertService.success(res.message);
        const index = this._parkingsService.lastMaintenances.findIndex(
          (l) => Number(l.carNumber) == this.order.car.carNumber
        );
        if (index > -1) {
          this._parkingsService.lastMaintenances[index].lastMaintinceCheck =
            moment().format();
          await this._parkingsService.applyFilterParkingsByUserMode();
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._alertService.error('אירעה שגיאה בשליחת הנתונים: ' + err.error);
      });
  }

  selectAll() {
    console.log(this.selectAllVal);
    this._arrayItems.forEach((i) => {
      i.value = this.selectAllVal;
    });
  }

  async navigateToCar() {
    const carLocation = this.order.lastCarLocation
      ? this.order.lastCarLocation
      : await this.queryCarLocation(this.order.car.carNumber);

    if (!carLocation) {
      this._alertService.error('לא הצליח לקבל את מיקום הרכב');
      return;
    }

    this._globalService.navigateToCar(carLocation.lat, carLocation.lon);
  }

  async queryCarLocation(carNumber: number): Promise<MapLocation> {
    try {
      const cars = await this.httpService.get(
        this._urlService.nodeUrl() + 'cars?carNumber=' + carNumber
      );
      return { lat: cars[0].latitude, lon: cars[0].longitude };
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public performActionOnDoors(action: boolean) {
    this.enableOpenDoors = false;
    setTimeout(() => {
      this.enableOpenDoors = true;
    }, 10000);
    const body = {
      orderId: this.order.id,
      updateSource: AppGlobals.ID,
      Open: action,
    };
    this.httpService
      .post(this._urlService.baseUrl() + 'orders/Doors', body, true)
      .then((res: { isSucceeded: boolean }) => {
        console.log(res);
        if (res.isSucceeded === true) {
          this._alertService.success('פעולתך בוצעה בהצלחה');
        } else {
          this._alertService.error('הפעולה נכשלה! אנא נסה שוב');
          this.enableOpenDoors = true;
        }
      })
      .catch((error) => {
        console.error(error);
        this._alertService.error('הפעולה נכשלה! אנא נסה שוב');
        this.enableOpenDoors = true;
      });
  }

  openGate() {
    const body = { ORDER_ID: this.order.id, UPDATE_SOURCE: AppGlobals.ID };
    this.enableOpenGate = false;
    this.httpService
      .post(this._urlService.baseUrl() + 'gates/OpenMyGate', body, true)
      .then(() => {
        this._alertService.success('פעולתך בוצעה בהצלחה');
        this.enableOpenGate = true;
      })
      .catch((error) => {
        console.log(error);
        this.enableOpenGate = true;
        this._alertService.error('הפעולה נכשלה! אנא נסה שוב');
      });
  }

  closeModal() {
    this.activeModal.close();
  }

}

class Picture {
  image: string;
  note: string;
  file: File;
}
