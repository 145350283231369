import { Pipe, PipeTransform, Inject, forwardRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { timeSlot } from '../models/car';
import { AppGlobals } from '../shared/app-globals/app-globals';
import { newDate, currentDate } from '../shared/function';
import { TimeSlotsPipe } from './time-slots.pipe';
@Pipe({
  name: 'carAvailabilitySuggestion',
})
export class CarAvailabilitySuggestionPipe implements PipeTransform {
  constructor(
    private _datepipe: DatePipe,

    @Inject(forwardRef(() => TimeSlotsPipe))
    private _timeSlotsPipe: TimeSlotsPipe
  ) {}

  transform(
    value: timeSlot[],
    orderStartTime?: Date,
    orderEndTime?: Date
  ): any {
    if (!value || !value.length) return [];

    const suggestionsArray: string[] = [];

    let lastEnd: string;

    value.forEach((v) => {
      let message: string;

      //if the suggestion is for less the hour ahead, not show it:
      if (
        newDate(v.start).getTime() - AppGlobals.TIMES.HOURS_IN_TIME >
        currentDate().getTime()
      ) {
        if (lastEnd) {
          message =
            ' נסה להתחיל אחרי ' +
            this._datepipe.transform(newDate(lastEnd), 'dd/MM/yy HH:mm') +
            ' ולסיים לפני ';
        } else message = ' נסה לסיים לפני ';
        message += this._datepipe.transform(newDate(v.start), 'dd/MM/yy HH:mm');
        suggestionsArray.push(message);
      }

      // if (newDate(v.end).getTime() - AppGlobals.TIMES.HOURS_IN_TIME > newDate().getTime())
      lastEnd = v.end;
    });

    if (lastEnd) {
      suggestionsArray.push(
        '  נסה להתחיל אחרי ' +
          this._datepipe.transform(newDate(lastEnd), 'dd/MM/yy HH:mm')
      );
    }
    return suggestionsArray;
  }
}
