<app-header></app-header>

<div class="leftDiv" *ngIf="_appStart.show"></div>
<div class="rightDiv" *ngIf="_appStart.show"></div>
<div>
  <div class="routerContainer">
    <router-outlet (activate)="changeOfRoutes()"></router-outlet>
  </div>
</div>

<app-alert></app-alert>
<app-loading></app-loading>

<!-- hidden map to load google maps api: -->
<agm-map></agm-map>
