import { Component, Inject, forwardRef } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { GoogleAuthService } from '../../services/google-auth.service';
import { LoadingService } from '../../services/loading.service';
import { AppStartService } from '../../services/app-start.service';
import { AppGlobals } from '../app-globals/app-globals';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {

  isLogin = true;
  connect = 'מנוי קיים';

  constructor(
    private _googleAuth: GoogleAuthService,
    private _alertService: AlertService,
    private _appStart: AppStartService,
    private _loadingService: LoadingService
  ) { }


  googleAuthenticate = () => {
    this._loadingService.startLoading();
    if (this.isLogin) {
      this.isLogin = false;
      const errorCallback = (error) => {
        console.log(JSON.stringify(error));
        this._loadingService.stopLoading();
        this.isLogin = true;
        this._alertService.error('אירעה שגיאה בשירות התחברות באמצעות גוגל');
        console.error('אירעה שגיאה בשירות התחברות באמצעות גוגל');
      };
      this._googleAuth.login(
        this.callback,
        errorCallback,
      );
    }
  }

  callback = (token: string) => {
    this._appStart.ValidateUser();
    console.log('token', token);
    this.isLogin = true;
  };
}
