import { carTimeSlots } from '../pipes/time-slots.pipe';

export class Car {
  amountOfSeats: number;
  carCategory: number;
  carGearType: string;
  carType: string;
  gasType: string;
  isA2BCar: boolean;
  lastWashDate: string;
  lastMaintenanceDate: Date;
  availability: number;
  carNumber: number;
}

export class CarInParking extends Car {
  errorMessage: string;
  timeSlots: timeSlot[];
  spacesInTimeSlots: timeSlot[];
  carTimeSlots: carTimeSlots[];
}

export class timeSlot {
  start: string;
  end: string;
  odt: number;
}
