import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AlertMessage, AlertService } from '../../services/alert.service';
import { ModalService } from '../../services/modal.service';

@Component({
  moduleId: module.id,
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
})
export class AlertComponent implements OnInit {
  message: AlertMessage;

  constructor(
    private _alertService: AlertService,
    private _modalService: ModalService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this._alertService.getMessage().subscribe(async (message) => {
      if (message) {
        while (this.message) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        this.showMessage(message);
      }
    });
  }

  showMessage(message: AlertMessage) {
    this.message = message;

    setTimeout(() => {
      this.message = null;
    }, this.message.timeout);

    if (!this.cdRef['destroyed']) {
      this.cdRef.detectChanges();
    }
    console.log('message', message);
  }

  clear() {
    this._alertService.clear();
  }

  showAlert(message: string) {
    this._modalService.alert('', message);
  }
}
