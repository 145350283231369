import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import PageTitle from '../../models/page-title';
import { City } from '../../models/city';
import { Order } from '../../models/order';
import { Parking } from '../../models/parking';
import { UsersPreference } from '../../models/user';
import { OrderService } from '../../services/order.service';
import { ParkingsService } from '../../services/parkings.service';
import { EmployeeType, UserService } from '../../services/user.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LocationComponent implements OnInit {
  EmployeeType = EmployeeType;

  get pageTitle(): PageTitle {
    return {
      title: 'ORDER_BY_STATION',
      previousUrl: '/menu',
    };
  }

  cities: City[];
  permittedCities: any[];
  parkings: Parking[] = null;
  neighborhoods: string[];
  selectedCity = 0;
  selectedNeighborhood: string;
  selectedNeighborhoods: string[] = new Array<string>();
  preferncesParkings: UsersPreference;
  showPrefernces = false;
  showAllParkings = false;

  constructor(
    private _parkingsService: ParkingsService,
    private _orderService: OrderService,
    private _router: Router,
    public _userService: UserService
  ) {}

  async ngOnInit() {
    await this._parkingsService.loadUserRegionPermissions();
    this.permittedCities = Array.from(
      new Set(this._parkingsService.permittedParkings.map((p) => p.city))
    );
    console.log(this.permittedCities);

    await this._parkingsService.applyFilterParkingsByUserMode();
    this._parkingsService.getAllParkingsCities().subscribe((cities: City[]) => {
      console.log(cities);
      this.cities = cities.filter(
        (c) =>
          this.permittedCities.includes(c.name) &&
          this._parkingsService.parkings.filter((p) => p.cityId == c.id)
            .length > 0
      );
      console.log(this.cities);
    });
  }

  getStationsByCity(cityId: number) {
    this.showPrefernces = false;
    this.parkings = [];
    this.selectedNeighborhoods = [];

    if (this.selectedCity != cityId) {
      this._parkingsService.getParkingsByCityId(cityId).subscribe(
        (parkings: Parking[]) => {
          this.parkings = parkings;
          console.log(
            Array.from(new Set(this.parkings.map((p) => p.neighborhood)))
          );
          this.neighborhoods = Array.from(
            new Set(this.parkings.map((p) => p.neighborhood))
          );
          console.log(this.neighborhoods.findIndex((n) => n == null));
          const index = this.neighborhoods.findIndex((n) => n == null);
          if (index > -1) {
            this.neighborhoods.splice(index, 1);
          }
          console.log(this.neighborhoods);

          this.selectedCity = cityId;
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.selectedCity = null;
    }
  }

  filterStationByNeighborhood(cityId: number, neighborhood: string) {
    console.log(neighborhood);
    this._parkingsService
      .getParkingsByCityId(cityId)
      .subscribe((parkings: Parking[]) => {
        console.log(parkings.filter((p) => p.neighborhood === neighborhood));
        this.parkings = parkings.filter((p) => p.neighborhood === neighborhood);
      });
  }

  selectParking(parkingIndex: number, carIndex: number) {
    const order = new Order();
    this._orderService.StartParking = this.parkings[parkingIndex];
    order.startTime = moment().add(2, 'minutes').set('s', 0).toDate();
    order.endTime = moment(order.startTime).add(20, 'minutes').toDate();
    order.period = 1;
    order.startParking = this.parkings[parkingIndex];
    console.log(order);
    this._orderService.changeCurrentOrder(order);

    this._router.navigate(['/order/stationsInMap']);
  }

  neighborhoodSelected(event) {
    console.log(event.target.value);
  }

  onChange($event) {
    console.log($event);
    console.log(this.selectedNeighborhoods);
    this._parkingsService
      .getParkingsByCityId(this.selectedCity)
      .subscribe((parkings: Parking[]) => {
        if (this.selectedNeighborhoods.length > 0) {
          this.parkings = parkings.filter((p) =>
            this.selectedNeighborhoods.includes(p.neighborhood)
          );
        } else {
          this.parkings = parkings;
        }
      });
  }

  getPrefernceParkings() {
    this.showPrefernces = !this.showPrefernces;
    this.selectedCity = null;
    this.parkings = [];
    this.parkings = this._parkingsService.parkings.filter((p) =>
      this.preferncesParkings.parkings.includes(p.id)
    );
    console.log(this.parkings);
  }

  async toggleShowAllParkings() {
    this.showAllParkings = !this.showAllParkings;
    if (this.showAllParkings) {
      const parkings = this._parkingsService.OriginalParkings;
      this._parkingsService.parkings = parkings;
    } else {
      await this._parkingsService.applyFilterParkingsByUserMode();
    }
    this._parkingsService.getAllParkingsCities().subscribe((cities: City[]) => {
      console.log(cities);
      this.cities = cities;
      if (!this.showAllParkings) {
        this.cities = cities.filter(
          (c) =>
            this.permittedCities.includes(c.name) &&
            this._parkingsService.parkings.filter((p) => p.cityId == c.id)
              .length > 0
        );
      }
      console.log(this.cities);
    });
  }

  get showAllParkingsButton(): string {
    return this.showAllParkings ? 'סנן תחנות' : 'טען כל התחנות';
  }
}
