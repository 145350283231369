<app-page-title [pageTitle]="pageTitle"></app-page-title>
<section id="open-area" class="fullwidth">
  <!--preference parkings-->
  <div *ngIf="preferncesParkings">
    <div class="routerContainer">
      <div class="title">
        <div class="col-xs-12 text-center">
          <h4 class="graytext" translate>SELECT_STATION</h4>
        </div>
      </div>
    </div>
    <div class="routerContainer" dir="rtl">
      <div
        class="routerContainer"
        style="padding-right: 0px; padding-left: 0px"
      >
        <div class="panel panel-default">
          <div class="panel-heading">
            <!-- .panel-heading starts -->
            <h3 class="areatitle panel-title">
              <a (click)="getPrefernceParkings()">
                <img
                  class="parkingImg"
                  style="margin-left: 14px"
                  src="assets/images/icon_pointer_citycar.png"
                  width="30"
                  alt=""
                />FAVORITE</a
              >
            </h3>
          </div>
          <div>
            <!--parkings-->
            <div *ngIf="parkings && showPrefernces" class="panel-collapse">
              <!-- .panel-collapse starts -->
              <div class="area">
                <!-- .area starts -->
                <div class="col-xs-12">
                  <ul>
                    <li>
                      <span *ngIf="parkings.length === 0" translate
                        >PARKING</span
                      >
                      <div
                        *ngFor="let parking of parkings; let p_i = index"
                        (click)="selectParking(p_i, 0)"
                      >
                        <a (click)="selectParking(p_i, 0)">
                          <div class="col-xs-12">
                            <p>
                              <img src="assets/images/icon_car.png" alt="" />{{
                                parking.name
                              }}
                            </p>
                          </div>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="_userService.userMode === EmployeeType.Maintenance"
    class="routerContainer"
    style="padding: 5px; margin-bottom: 5px"
  >
    <div class="title">
      <div class="col-xs-12 text-center" style="padding-bottom: 5px">
        <button (click)="toggleShowAllParkings()">
          {{ showAllParkingsButton }}
        </button>
      </div>
    </div>

    <div class="title">
      <div class="col-xs-12 text-center">
        <h4 class="graytext" translate>SELECT_AREA</h4>
      </div>
    </div>
  </div>
  <div class="routerContainer" dir="rtl">
    <div class="routerContainer" style="padding-right: 0px; padding-left: 0px">
      <div class="panel-group">
        <div class="panel panel-default" *ngFor="let city of cities">
          <!-- .panel starts -->
          <div class="panel-heading">
            <!-- .panel-heading starts -->
            <h3 class="areatitle panel-title">
              <a (click)="getStationsByCity(city.id)">
                <img
                  class="parkingImg"
                  style="margin-left: 14px"
                  src="assets/images/icon_pointer_citycar.png"
                  width="30"
                  alt=""
                />{{ city.name }}</a
              >
            </h3>
          </div>
          <!-- .panel-heading ends -->
          <div
            *ngIf="
              selectedCity === city.id && parkings && neighborhoods.length > 0
            "
            class="panel-collapse select-div"
          >
            <ng-select
              class="custom"
              [items]="neighborhoods"
              [multiple]="true"
              [closeOnSelect]="true"
              [addTag]="false"
              autofocus
              placeholder="&nbsp;&nbsp;סנן לפי שכונה&nbsp;&nbsp;"
              notFoundText="מידע לא נמצא..."
              [(ngModel)]="selectedNeighborhoods"
              [ngModelOptions]="{ standalone: true }"
              (change)="onChange($event)"
            >
              <ng-template ng-option-tmp let-item="item">
                <input
                  [checked]="selectedNeighborhoods.indexOf(item) > -1"
                  type="checkbox"
                />
                &nbsp; {{ item }} &nbsp;
              </ng-template>
            </ng-select>
          </div>
          <div
            *ngIf="selectedCity === city.id && parkings"
            class="panel-collapse"
          >
            <!-- .panel-collapse starts -->
            <div class="area">
              <!-- .area starts -->
              <div class="col-xs-12">
                <ul>
                  <li>
                    <span *ngIf="parkings.length === 0" translate>PARKING</span>
                    <div
                      *ngFor="let parking of parkings; let p_i = index"
                      (click)="selectParking(p_i, 0)"
                    >
                      <a (click)="selectParking(p_i, 0)">
                        <div class="col-xs-12">
                          <p>
                            <img src="assets/images/icon_car.png" alt="" />{{
                              parking.name
                            }}
                          </p>
                        </div>
                      </a>
                      <!-- <a *ngFor="let carDetails of parking.carsDetails; let c_i=index"
                    (click)="selectParking(p_i, c_i)"
                      >
                      <div class="col-xs-12">
                        <p>
                          <img src="assets/images/icon_car.png" alt="">{{parking.name+' - '+carDetails.carType}}</p>
                      </div>
                    </a> -->
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- .area ends -->
          </div>
          <!-- .panel-collapse ends -->
        </div>
      </div>
    </div>
  </div>
</section>
