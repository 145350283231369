import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Optional,
} from '@angular/core';
import { Router } from '@angular/router';
import { Period } from '../../enums/index';
import { Subject } from 'rxjs/Subject';
import { ValidatorsList } from '../../classes';
import PageTitle from '../../models/page-title';
import { timeSlot } from '../../models/car';
import { AlertService } from '../../services/alert.service';
import { OrderService } from '../../services/order.service';
import { AppGlobals } from '../../shared/app-globals/app-globals';
import { currentDate, newDate } from '../../shared/function';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

const ONE_HUER_OFFSET = 3600000;

@Component({
  selector: 'app-select-time',
  templateUrl: './select-time.component.html',
  styleUrls: ['./select-time.component.css'],
})
export class SelectTimeComponent implements OnInit {
  @Input()
  isShowSmallFormat = false;

  @Output()
  public changeTime: EventEmitter<any> = new EventEmitter();

  anotherPeriod: Period;
  updateDates: Subject<any>;

  orderForNowInterval;
  private _isOrderForNow = true;
  public get isOrderForNow(): boolean {
    return this._isOrderForNow;
  }
  public set isOrderForNow(v: boolean) {
    this._isOrderForNow = v;
    if (v) {
      this.isOrderForClosedShabbat = false;
      this.orderForNowInterval = setInterval(() => {
        this._startTime = new Date(
          currentDate().getTime() + AppGlobals.TIMES.MINUTES_IN_TIME * 2
        );
        if (!this.isOrderForClosedShabbat) this.setDefaultEndTime();
      }, 100);
    } else {
      clearInterval(this.orderForNowInterval);
    }
  }

  isOrderForClosedShabbat: boolean = this._orderService.isOrderForClosedShabbat;

  get pageTitle() {
    const PageTitle = <PageTitle>{
      title: 'SELECT_RENTAL_TYPE',
      previousUrl: '/menu',
    };
    return PageTitle;
  }

  get hourlyImgUrl(): string {
    if (this.slectedPeriod == Period.hours)
      return AppGlobals.PERIOD_IMAGES.HOURLY_SELECTED_IMG_URL;
    return AppGlobals.PERIOD_IMAGES.HOURLY_NOT_SELECTED_IMG_URL;
  }

  get daylyImgUrl(): string {
    if (this.slectedPeriod == Period.days)
      return AppGlobals.PERIOD_IMAGES.DAYLY_SELECTED_IMG_URL;
    return AppGlobals.PERIOD_IMAGES.DAYLY_NOT_SELECTED_IMG_URL;
  }

  get longTermImgUrl(): string {
    if (this.slectedPeriod == Period.LongTerm)
      return AppGlobals.PERIOD_IMAGES.SEVEN_HOURS_SELECTED_IMG_URL;
    return AppGlobals.PERIOD_IMAGES.SEVEN_HOURS_NOT_SELECTED_IMG_URL;
  }

  private _slectedPeriod: Period;
  public get slectedPeriod(): Period {
    return this._slectedPeriod;
  }
  public set slectedPeriod(v: Period) {
    this._slectedPeriod = v;

    if (v == Period.days) {
      this.timeAmount = 1;
    }
    if (v == Period.hours) {
      this.timeAmount = 2;
    }
    if (v == Period.LongTerm) {
      this.timeAmount = 7;
    }
  }

  get periodHebrewName(): string {
    switch (this.slectedPeriod) {
      case 1:
        return 'HOURS_RENTAL';

      case 3:
        return 'DAYS_RENTAL';

      case 4:
        return 'LONG_RENTAL';
    }
  }

  get periodName(): string {
    return Period[this.slectedPeriod];
  }

  private _startTime: Date = currentDate();
  public get startTime(): Date {
    return this._startTime;
  }
  public set startTime(v: Date) {
    if (!isNaN(new Date(v).valueOf())) {
      v.setSeconds(0);
      console.log(v);

      this._startTime = new Date(v);

      this.isOrderForNow = false;

      // this._orderService.isOrderForNow = false;
      if (!this.isOrderForClosedShabbat) {
        this.setDefaultEndTime();
      }
    }
  }
  startTimeValidations: ValidatorsList = new ValidatorsList([
    {
      validator: (date: Date) => date < currentDate(),
      errorMessage: 'לא ניתן להוסיף הזמנות על זמן העבר',
    },
  ]);

  private _endTime: Date;
  public get endTime(): Date {
    return this._endTime;
  }
  public set endTime(v: Date) {
    if (!isNaN(new Date(v).valueOf())) {
      v.setSeconds(0);
      console.log(v);

      this._endTime = new Date(v);

      if (!this.isOrderForClosedShabbat && !this._orderService.isOfficeOrder) {
        this.roundStartTime();
      }

      // update time_amount when end_time changes:
      this._timeAmount = this.getDiffDates();
    }
  }

  endTimeValidations: ValidatorsList = new ValidatorsList([
    {
      validator: (date) => date < this.startTime,
      errorMessage: 'תאריך סיום קטן מתאריך התחלה',
    },
    {
      validator: (date) => !this.timeAmount,
      errorMessage: 'לא בחרת טווח להזמנה',
    },
  ]);

  get isTimesValid(): boolean {
    return (
      this.startTimeValidations.isValid(this.startTime) &&
      this.endTimeValidations.isValid(this.endTime)
    );
  }

  private _extraHours: number;
  public get extraHours(): number {
    return Math.ceil(
      ((this.endTime.getTime() - this.startTime.getTime()) /
        AppGlobals.TIMES.HOURS_IN_TIME) %
        24
    );
  }
  public set extraHours(v: number) {
    this._extraHours = v;
  }

  get isShowExtraHours(): boolean {
    return this.slectedPeriod == Period.days;
  }

  private _timeAmount: number;
  public get timeAmount(): number {
    return Math.floor(this._timeAmount);
  }
  public set timeAmount(timeAmount: number) {
    this._timeAmount = timeAmount;

    if (!this.isOrderForClosedShabbat) this.setDefaultEndTime();
  }

  get timeAmountInTime(): number {
    let timeInHours: number = this.timeAmount;
    if (this.slectedPeriod === Period.days) timeInHours *= 24;
    return timeInHours * ONE_HUER_OFFSET;
  }

  constructor(
    private _router: Router,
    private _orderService: OrderService,
    private _alertService: AlertService,
    @Optional() private activeModal: NgbActiveModal,
  ) {}

  ngOnInit() {
    this.resetDetails();
  }

  toggleOrderForNow() {
    this.isOrderForNow = !this.isOrderForNow;
  }

  choosePeriod(periodName: string): void {
    this.slectedPeriod = Period[periodName];
  }

  saveDetails(): void {
    this._orderService.Dates = [this.startTime, this.endTime];
    this._orderService.Period = this.slectedPeriod;
    this._orderService.isOrderForNow = this.isOrderForNow;
    this._orderService.isOrderForClosedShabbat = this.isOrderForClosedShabbat;

    if (this.isShowSmallFormat) {
      console.log('isShowSmallFormat');
      this.changeTime.emit(1);
    } else {
      this._router.navigate(['order/stationsInMap']);
    }
    this.activeModal?.close(true);
  }

  resetDetails(): void {
    //set default period:
    if (this._orderService.Period)
      this.slectedPeriod = this._orderService.Order.period;
    else this.slectedPeriod = Period.hours;

    //set default times:
    if (this._orderService.Order.startTime) {
      this.startTime = this._orderService.Order.startTime;
      this.endTime = this._orderService.Order.endTime;
    } else {
      this.startTime = new Date(
        currentDate().setHours(currentDate().getHours() + 1)
      );

      //set default time amount:
      if (this.slectedPeriod === Period.days) this.timeAmount = 1;
      else this.timeAmount = 2;
    }

    this._startTime.setSeconds(0);
    this._startTime.setMilliseconds(0);

    this._endTime.setSeconds(0);
    this._endTime.setMilliseconds(0);

    this.isOrderForNow = this._orderService.isOrderForNow;
    this.isOrderForClosedShabbat = this._orderService.isOrderForClosedShabbat;
    console.log(this._orderService.Order);
    this.startTime = this._orderService.Order.startTime;
    this.endTime = this._orderService.Order.endTime;
  }

  //set default end time according to time amount:
  setDefaultEndTime() {
    this._endTime = new Date(this._startTime);
    this._endTime.setTime(this._startTime.getTime() + this.timeAmountInTime);

    const startTimeZoneOffset = this._startTime.getTimezoneOffset();
    const endTimeZoneOffset = this._endTime.getTimezoneOffset();

    if (endTimeZoneOffset != startTimeZoneOffset) {
      if (endTimeZoneOffset > startTimeZoneOffset) {
        this._endTime.setTime(this._endTime.getTime() + ONE_HUER_OFFSET);
      } else {
        this._endTime.setTime(this._endTime.getTime() - ONE_HUER_OFFSET);
      }
    }
  }

  roundStartTime(): void {
    this._startTime = new Date(this._startTime);
  }

  getDiffDates(): number {
    const diffInHours: number = Math.ceil(
      Math.abs(this.endTime.valueOf() - this.startTime.valueOf()) /
        60 /
        60 /
        1000
    );
    if (this.slectedPeriod == Period.days) return diffInHours / 24;
    return diffInHours;
  }

  addToTimeAmount() {
    this.timeAmount++;
  }
  removeFromTimeAmount() {
    if (this.timeAmount > 1) this.timeAmount--;
  }
  addToExtraHours() {
    if (this.extraHours < 23)
      this.endTime = new Date(
        this.endTime.getTime() + AppGlobals.TIMES.HOURS_IN_TIME
      );
  }
  removeFromExtraHours() {
    if (this.extraHours > 0)
      this.endTime = new Date(
        this.endTime.getTime() - AppGlobals.TIMES.HOURS_IN_TIME
      );
  }

  getClosedShabbatOrderTime() {
    this.isOrderForNow = false;

    if (this.isOrderForClosedShabbat) {
      this.isOrderForClosedShabbat = false;
      this._orderService.isOrderForClosedShabbat = false;
      this.resetDetails();
      return;
    }

    this.isOrderForClosedShabbat = !this.isOrderForClosedShabbat;

    this._orderService.getClosedShabbatOrderTime(this.startTime).subscribe(
      (orderTimeSlot: timeSlot) => this.updateDatesFromServer(orderTimeSlot),
      () => {
        this._alertService.error('אירעה שגיאה, נסה שנית');
      }
    );
  }

  updateDatesFromServer(orderTimeSlot: timeSlot): any {
    {
      this.slectedPeriod = Period.hours;
      this.startTime = newDate(orderTimeSlot.start);
      this.endTime = newDate(orderTimeSlot.end);
    }
  }
}
