import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import VConsole from 'vconsole';

// const vConsole = new VConsole();

if (environment.production) {
  enableProdMode();
  if (window) {
    // window.console.log = function () { };
  }
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.register('/ngsw-worker.js');
    }
  })
  .catch((err) => console.log(err));
