import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  Inject,
  forwardRef,
} from '@angular/core';
import { DialogRef } from 'ngx-modialog-7';
import { FormGroup, FormBuilder } from '@angular/forms';
import { from } from 'rxjs/observable/from';
import { UrlService } from '../../services/url.service';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import {
  BaseModalComponent,
  ModalContext,
} from '../base-modal/base-modal.component';
import { Car } from '../../models/car';
import { AlertService } from '../../services/alert.service';
import { HttpService } from '../../services/http.service';
import { ParkingsService } from '../../services/parkings.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-report-maintenance',
  templateUrl: './report-maintenance.component.html',
  styleUrls: ['./report-maintenance.component.css'],
})
export class ReportMaintenanceComponent
  extends BaseModalComponent<ModalContext<any>>
  implements OnInit
{
  car: Car;
  selectAllVal: any;
  _maintenanceNotes: string;
  _maintenanceForm: FormGroup;
  _finishOrder: boolean;

  context: ModalContext<any>;
  _arrayItems: {
    item: string;
    id: number;
    value: number;
  }[] = [];

  afterClose(): void {
    console.log('afterClose');
  }

  constructor(
    public dialog: DialogRef<ModalContext<any>>,
    elementRef: ElementRef,
    renderer: Renderer2,
    @Inject(forwardRef(() => UserService))
    private _userService: UserService,
    @Inject(forwardRef(() => AlertService))
    private _alertService: AlertService,
    @Inject(forwardRef(() => ParkingsService))
    private _parkingsService: ParkingsService,
    @Inject(forwardRef(() => UrlService))
    private _urlService: UrlService,
    @Inject(forwardRef(() => HttpService))
    private _httpService: HttpService,
    private _formBuilder: FormBuilder
  ) {
    super(dialog, elementRef, renderer);
    this.context = dialog.context;
    this.afterClose = dialog.context.afterCloseFn;
    console.log(this.context);
    this.car = this.context.data;
    this._maintenanceForm = this._formBuilder.group({
      Array: this._formBuilder.array([]),
    });
  }

  async ngOnInit() {
    this.initModal();
    const items = await this._parkingsService.checkListItems;
    for (let index = 0; index < items.length; index++) {
      this._arrayItems.push(<any>{ id: index, item: items[index] });
    }
    console.log(this._arrayItems);
  }

  selectAll() {
    console.log(this.selectAllVal);
    this._arrayItems.forEach((i) => {
      i.value = this.selectAllVal;
    });
  }

  finishcarMaintenance() {
    console.log(this._arrayItems);
    this._arrayItems.forEach((i) => {
      if (i.value == null) {
        i.value = 3;
      }
    });
    console.log(this.car.carNumber);
    const body = {
      UserId: this._userService.userPermission.email,
      CarId: this.car.carNumber,
      CheckList: this._arrayItems,
      CarType: this.car.carType,
      Notes: this._maintenanceNotes,
      ShouldFinish: this._finishOrder,
    };
    from(
      this._httpService.post(
        this._urlService.nodeUrl() + 'cars/maintenance/add',
        body
      )
    ).subscribe(
      async (res) => {
        console.log(res);
        this._alertService.success(res.message);
        const index = this._parkingsService.lastMaintenances.findIndex(
          (l) => Number(l.carNumber) == this.car.carNumber
        );
        if (index > -1) {
          this._parkingsService.lastMaintenances[index].lastMaintinceCheck =
            moment().format();
          console.log(this._parkingsService.lastMaintenances[index]);
          await this._parkingsService.applyFilterParkingsByUserMode();
          this.closeDialog();
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        this._alertService.error('אירעה שגיאה בשליחת הנתונים: ' + err.error);
        this.closeDialog();
      }
    );
  }
}
