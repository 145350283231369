import { Component } from '@angular/core';
import { Order } from '../../models/order';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { Modal } from 'ngx-modialog-7/plugins/bootstrap';
import PageTitle from '../../models/page-title';
import * as moment from 'moment';
import { OrderService } from '../../services/order.service';
import { EmployeeType, UserService } from '../../services/user.service';
import { AppGlobals } from '../../shared/app-globals/app-globals';
import { ParkingsService } from '../../services/parkings.service';
import { CarsInRent } from '../../services/cars-in-rent.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  providers: [TranslatePipe],
})
export class MenuComponent {
  get pageTitle(): PageTitle {
    return {
      title: 'MENU',
      previousUrl: '/home',
    };
  }

  get CONTACT_DETAILS() {
    return AppGlobals.CONTACT_DETAILS;
  }

  get menueType() {
    const mode = this._userService.userMode;
    switch (mode) {
      case EmployeeType.Wash:
        return 'שטיפה';
      case EmployeeType.Maintenance:
        return 'תחזוקה';
      default:
        break;
    }
  }

  constructor(
    public modal: Modal,
    private _orderService: OrderService,
    private _router: Router,
    private _userService: UserService,
    private _parkingsService: ParkingsService,
    private carsInRent: CarsInRent,
  ) { }

  showCarsInRent() {
    this.carsInRent.showCarsInRent()
  }

  async openNewOrder(): Promise<void> {
    const order = new Order();
    order.startTime = moment().add(2, 'minutes').set('s', 0).toDate();
    order.endTime = moment(order.startTime)
      .add(AppGlobals.DEFAULT_OFFICE_DRIVE_TIME, 'milliseconds')
      .toDate();
    order.period = 1;
    this._orderService.isOfficeOrder = true;
    console.log(order);
    if (!this._orderService.ordersList) {
      const orders = await this._orderService.getFutureOrders().toPromise();
      const parkingsToLoad = orders
        .filter((o) => o.startParking)
        .map((o) => o.startParking.id);
      console.log(parkingsToLoad);
      this._parkingsService.parkingsToLoadFirst = parkingsToLoad;
      this._orderService.ordersList = orders;
    }
    this._orderService.isOrderForNow = true;
    this._orderService.changeCurrentOrder(order);
    console.log('openNewOrder');
    this._router.navigate(['/order/stationsInMap']);
  }

  damageReport(): void {
    this._router.navigate(['/damageReport']);
  }

  showCurrenActive() {
    return true;
  }
}
