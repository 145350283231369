import { AppGlobals } from '../shared/app-globals/app-globals';
import { currentDate } from '../shared/function';
import { CarInParking, timeSlot } from './car';
import { MapLocation } from './map-location';
export class Parking {
  earthLocation: MapLocation;
  id: number;
  name: string;
  carsDetails: Array<CarInParking>;
  isA2BParking: boolean;
  neighborhood: string;
  washFrequncyInDays: number;
  maintenanceFrequncyInDays: number;
  cityId: number;
  hasGateOpener: boolean
}

export class ParkingOnMap extends Parking {
  private _lastReloadDate: Date;
  public get lastReloadDate(): Date {
    return this._lastReloadDate;
  }
  public set lastReloadDate(v: Date) {
    this._lastReloadDate = v;
  }

  isWithDetails: boolean;

  checkedTimeSlots: timeSlot[] = [];
  timeOutToReload: number = AppGlobals.MINUTES_TO_RELOAD_PARKINGS * 60000;

  constructor(parking: Parking, isWithDetails: boolean = false) {
    super();
    this.earthLocation = parking.earthLocation;
    this.id = parking.id;
    this.name = parking.name;
    this.carsDetails = parking.carsDetails;
    this.isA2BParking = parking.isA2BParking;
    this.neighborhood = parking.neighborhood;
    this.cityId = parking.cityId;

    this.isWithDetails = isWithDetails;
    if (isWithDetails) this.lastReloadDate = currentDate();
  }

  //reload parking if it is without details, or it's time out passed:
  get isTimeToReload(): boolean {
    return (
      !this.isWithDetails ||
      //parking's time out passed:
      currentDate().getTime() >
        this.lastReloadDate.getTime() + this.timeOutToReload
    );
  }
}
