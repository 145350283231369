import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { DatePipe } from '@angular/common';
import { GlobalService } from './global.service';
import { from } from 'rxjs/observable/from';
import { UrlService } from './url.service';

const WASH_DESCRIPTION = 'להוסיף נסיעת שטיפה';
const MAINTENANCE_DESCRIPTION = 'לבצע תחזוקה';
import { ParkingOnMap } from '../models/parking';
import {
  User,
  UsersPreference,
  UserBody,
  RegisteringUserObject,
  UserPermission,
} from '../models/user';
import { AppGlobals } from '../shared/app-globals/app-globals';
import { HttpService } from './http.service';
import { CreditCartDetails } from '../models/credit-cart-details';
import { last, map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from './alert.service';
import { ReplaySessionService } from './replay-session.service';

@Injectable()
export class UserService {
  baseUserUrl = 'users/a';
  baseUpdateUserUrl = 'subscriptionUpdates';

  private _user: User;
  public get user(): User {
    return this._user;
  }
  public set user(v: User) {
    this._user = v;
  }

  private _userPermission: UserPermission;
  public get userPermission(): UserPermission {
    return this._userPermission;
  }
  public set userPermission(user: UserPermission) {
    if (user) {
      this.replaySessionService.tracker.setUserID(
        user?.userID?.toString() || user?.userIID?.toString()
      );
      this.replaySessionService.sendEventToReplaySession('full details', user);
    }
    this._userPermission = user;
  }

  userType: EmployeeType;

  _userMode: EmployeeType;
  public get userMode(): EmployeeType {
    return this._userMode;
  }

  public set userMode(value: EmployeeType) {
    this._userMode = value;
  }

  usersPreference = new UsersPreference();
  isUpdate = false;
  isLicenseUpdate = false;
  isCreditUpdate = false;
  creditUpdateFromUserProfile: boolean;

  constructor(
    private _httpService: HttpService,
    private _datePipe: DatePipe,
    private _globalService: GlobalService,
    private _urlService: UrlService,
    private translateService: TranslateService,
    private alertService: AlertService,
    private replaySessionService: ReplaySessionService,
  ) { }

  getUserDetailsPromise = async (
    isShowLoading: boolean = true,
    isUpdate: boolean = false
  ): Promise<UserPermission> => {
    if (this.user) {
      return this.userPermission;
    }
    const url = !isUpdate ? this.baseUserUrl : this.baseUpdateUserUrl;
    try {
      return this.userPermission = await this._httpService.get<UserPermission>(
        this._urlService.baseUrl() + url,
        isShowLoading
      );
    } catch (error: any) {
      this.translateService
        .get('MESSAGES.GET_USER_DETAILS_ERROR_MESSAGE')
        .pipe(last())
        .subscribe((message) => {
          this.alertService.error(message, true);
        });

      console.log(`Error:+ ${error}`);
      this.alertService.error(error);
    }
  }

  SetEmployeeType(user: UserPermission): void {
    const userPermissions = user?.permissions;
    if (userPermissions?.includes(WASH_DESCRIPTION) && userPermissions?.includes(MAINTENANCE_DESCRIPTION)) {
      this.userType = EmployeeType.MaintenanceAndWash;
    } else if (userPermissions?.includes(WASH_DESCRIPTION)) {
      this.userType = EmployeeType.Wash;
    } else if (userPermissions?.includes(MAINTENANCE_DESCRIPTION)) {
      this.userType = EmployeeType.Maintenance;
    }
    console.log(userPermissions);
  }

  setUserModeByEmployeeType(userType: EmployeeType) {
    this.userMode = userType;
  }

  updateUserDetails(user: User): Observable<User> {
    return this.updateDetails({
      nameInInvoice: user.nameInInvoice,
      city: user.city !== this.user.city ? user.city : null,
      street: user.street,
      streetNumber: 0,
    });
  }

  updateCreditCardDetails(
    creditCardDetails: CreditCartDetails
  ): Observable<User> {
    const body = {
      UpdateSubscriptionDetails: {
        NameInInvoice: this._user.nameInInvoice,
        City: this._user.city,
        Street: this._user.street,
        StreetNumber: 12,
        UpdateSource: AppGlobals.ID,

        NewCreditUpdate: {
          NewCreditCardNumber: creditCardDetails.number,
          NewCreditCardValidThrough: this._datePipe.transform(
            creditCardDetails.validThrough,
            'yyyy-MM-dd'
          ),
          NewCreditCardCVV: creditCardDetails.CVV,
          NewCreditCardHolderName: creditCardDetails.holderName,
          NewCreditCardHolderID: creditCardDetails.id,
          FourDigits: '7890',
        },
      },
    };

    return this.updateDetails(body);
  }

  register(
    user: RegisteringUserObject,
    isUpdate: boolean = false
  ): Observable<any> {
    const url = !isUpdate ? this.baseUserUrl : this.baseUpdateUserUrl;

    const registeringUserBody = this.custToUserBody(user);
    console.log('send user:', registeringUserBody);
    return from(
      this._httpService.post(
        this._urlService.baseUrl() + url + '/Register',
        registeringUserBody
      )
    );
  }

  private updateDetails(body: any): Observable<User> {
    return from(
      this._httpService.post(
        this._urlService.baseUrl() + 'subscriptions/Update',
        body
      )
    ).pipe(
      map((userBody: UserBody) => User.custToUserModel(userBody)),
      tap((user: User) => (this.user = user))
    )
  }

  custToUserBody(user: RegisteringUserObject): any {
    return this.custVariable(user);
  }

  custVariable(val: any): any {
    if (!val) return null;
    if (val instanceof Date) return this._datePipe.transform(val, 'yyyy-MM-dd');

    if (typeof val == 'object') {
      const newVal = <any>{};
      for (const key in val) {
        if (key[0] !== '_') {
          const subObj = this.custVariable(val[key]);
          if (subObj) newVal[key] = subObj;
        }
      }
      return newVal;
    }

    if (typeof val != 'function') return this._globalService.copyObject(val);
  }

  getPreferences(): Observable<UsersPreference> {
    const body = { clientId: this.user.identifier };
    return from(
      this._httpService.post(
        this._urlService.nodeUrl() + 'api/getPrefernces',
        body
      )
    ).pipe(
      tap((prederence: UsersPreference) => this.usersPreference = prederence)
    );
  }

  addPreference(parkings: ParkingOnMap[]): Observable<any> {
    const body = {
      clientId: this.user.identifier,
      parkings: parkings.map((p) => p.id),
    };
    return from(
      this._httpService.post(
        this._urlService.nodeUrl() + 'api/addPreference',
        body
      )
    );
  }

  updatePreference(parkings: ParkingOnMap[]): Observable<any> {
    const body = {
      clientId: this.user.identifier,
      parkings: parkings.map((p) => p.id),
    };
    return from(
      this._httpService.put(
        this._urlService.nodeUrl() + 'api/updatePreference',
        body
      )
    );
  }

  deletePreference(): Observable<any> {
    const body = { clientId: this.user.identifier };
    return from(
      this._httpService.post(
        this._urlService.nodeUrl() + 'api/deletePreference',
        body
      )
    );
  }
}

export enum EmployeeType {
  Wash = 0,
  Maintenance = 1,
  MaintenanceAndWash = 2,
}
