import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthState } from '../enums';
import { AppGlobals } from '../shared/app-globals/app-globals';
import { AlertService } from './alert.service';
import { GoogleAuthService } from './google-auth.service';
import { LoadingService } from './loading.service';
import { environment } from '../../environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ReplaySessionService } from './replay-session.service';

@Injectable()
export class HttpService {
  _baseUrl: string = AppGlobals.BASE_URL;
  _nodeServer: string = environment.NODE_SERVER;
  _baseDevUrl: string = AppGlobals.TEST_URL;

  constructor(
    private _httpClient: HttpClient,
    private _googleAuthService: GoogleAuthService,
    private _loadingService: LoadingService,
    private _alertService: AlertService,
    private translate: TranslateService,
    private replaySessionService: ReplaySessionService,
  ) { }

  async get<T>(
    url: string,
    isShowLoading: boolean = true,
  ): Promise<T> {
    try {
      if (isShowLoading) {
        this._loadingService.startLoading();
      }

      const headers = this.jwt();
      await this.asyncVerifyExistenceOfAccessToken();

      try {
        return await this._httpClient
          .get<T>(url, { headers, responseType: 'json' })
          .toPromise<T>();
      } catch (error: any) {
        console.log(error);
        this.handleError(error);
      } finally {
        if (isShowLoading) {
          this._loadingService.stopLoading();
        }
      }
    } catch (error: any) {
      console.log(error);
      this.handleError(error);
    }
  }

  async post(
    url: string,
    body: any = {},
    isShowLoading: boolean = true,
  ): Promise<any> {
    try {
      if (isShowLoading) this._loadingService.startLoading();

      const headers = this.jwt();
      await this.asyncVerifyExistenceOfAccessToken();

      try {
        return this._httpClient.post(url, body, { headers }).toPromise();
      } catch (error: any) {
        console.log(error);
        this.handleError(error);
      } finally {
        if (isShowLoading) {
          this._loadingService.stopLoading();
        }
      }
    } catch (error: any) {
      console.log(error);
      this.HandleFailure(error);
    }
  }

  async put(
    url: string,
    body: any = {},
    isShowLoading: boolean = true
  ): Promise<any> {
    try {
      if (isShowLoading) {
        this._loadingService.startLoading();
      }

      const headers = this.jwt();
      await this.asyncVerifyExistenceOfAccessToken();

      try {
        return this._httpClient.put(url, body, { headers }).toPromise();
      } catch (error: any) {
        console.log(error);
        this.handleError(error);
      } finally {
        if (isShowLoading) {
          this._loadingService.stopLoading();
        }
      }
    } catch (error: any) {
      console.log(error);
      this.HandleFailure(error);
    }
  }

  private handleError<T>(error: HttpErrorResponse) {
    this.replaySessionService.sendErrorToReplaySession(error)
    this._loadingService.stopLoading();
    console.log(error);
    const err = error;
    this._alertService.error(error.error, true);
    console.log(err);
    let message;

    if (error.status == 401 || error.status == 405 || error.status == 403) {
      if (error.error.error.includes('המשתמש לא פעיל')) {
        message = 'המנוי חסום - אנא פנה למוקד שירות הלקוחות *2319';
      } else if (
        error.error.error == AppGlobals.ERRORS_MASSAGES.FIND_USER.GET
      ) {
        message = AppGlobals.ERRORS_MASSAGES.FIND_USER.SET;
      } else if (
        error.error.error == AppGlobals.ERRORS_MASSAGES.FIND_SUBS.GET
      ) {
        message = AppGlobals.ERRORS_MASSAGES.FIND_SUBS.SET;
      } else if (
        error.error.error == AppGlobals.ERRORS_MASSAGES.PERMISSIONS.GET
      ) {
        message = AppGlobals.ERRORS_MASSAGES.PERMISSIONS.SET;
      } else {
        message =
          'יש להתחבר באמצעות חשבון google בלבד, נא וודא שחשבון gmail שלך מאושר במשרדי החברה';
      }

      setTimeout(() => {
        this._googleAuthService.logout(
          (() => {
            console.error(error);
            // alert(message);
            this._alertService.error(message, true);
          }).bind(this)
        );
      }, 100);
    } else {
      message = error.error.error ? error.error.error : error.error.message;
    }

    return this.translate
      .get('MESSAGES.GLOBAL_ERROR_MESSAGE')
      .pipe(
        map((_) => {
          throw throwError(message || this.translate.get('MESSAGES.GLOBAL_ERROR_MESSAGE'))
        }),
        catchError((error) => {
          throw throwError(error.error)
        })
      )
  }

  public jwt(): HttpHeaders {
    const access_token_object = JSON.parse(
      localStorage.getItem(AppGlobals.LOCAL_STORAGE.AccessTokenObject)
    );
    const access_token_type = localStorage.getItem(
      AppGlobals.LOCAL_STORAGE.AccessTokenType
    );
    const access_token =
      access_token_object != null ? access_token_object.access_token : null;
    if (access_token && access_token_type) {
      const httpHeaders = new HttpHeaders({
        [AppGlobals.LOCAL_STORAGE.AccessToken]: access_token,
        access_token_type: localStorage.getItem(
          AppGlobals.LOCAL_STORAGE.AccessTokenType
        ),
        'X-Requested-With': 'com.citycar.sayyes',
      });
      if (AppGlobals.AGENTS_USER_ID) {
        httpHeaders.set(
          AppGlobals.LOCAL_STORAGE.Agents,
          AppGlobals.AGENTS_USER_ID
        );
      }
      const authState = localStorage.getItem(
        AppGlobals.LOCAL_STORAGE.AuthState
      );
      if (authState) {
        httpHeaders.set('auth-state', authState);
      }

      return httpHeaders;
    }

    return new HttpHeaders();
  }

  public asyncVerifyExistenceOfAccessToken(): Promise<boolean> {
    return new Promise((resolve) => {
      const access_token_object = JSON.parse(
        localStorage.getItem(AppGlobals.LOCAL_STORAGE.AccessTokenObject)
      );
      const access_token =
        access_token_object != null ? access_token_object.access_token : null;

      if (!access_token) {
        const currState = localStorage.getItem(
          AppGlobals.LOCAL_STORAGE.AuthState
        );
        if (currState == AuthState.AUTHORIZED.toString()) {
          localStorage.setItem(
            AppGlobals.LOCAL_STORAGE.AuthState,
            AuthState.NOT_SET.toString()
          );
        }
        console.log('ERROR: access token missing');
        throw 'אירעה שגיאה! נסה להתחבר שנית';
      }
      resolve(true);
    });
  }

  private HandleFailure(error: Error) {
    this.replaySessionService.sendErrorToReplaySession(error)
    console.log(error);
    setTimeout(() => {
      this._googleAuthService.logout(
        (() => {
          this._alertService.error(error.message, true);
        }).bind(this)
      );
    }, 100);
  }
}
