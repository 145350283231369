import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import PageTitle from '../../models/page-title';
import { ParkingsService } from '../../services/parkings.service';
import { EmployeeType, UserService } from '../../services/user.service';

@Component({
  selector: 'app-mode-menue',
  templateUrl: './mode-menue.component.html',
  styleUrls: ['./mode-menue.component.css'],
})
export class ModeMenueComponent implements OnInit {
  EmployeeType: typeof EmployeeType = EmployeeType;;
  get pageTitle() {
    const PageTitle = <PageTitle>{
      title: 'MENU',
      previousUrl: '/home',
    };
    return PageTitle;
  }

  constructor(
    private userService: UserService,
    private router: Router,
    private parkingService: ParkingsService
  ) { }

  ngOnInit() {
    console.log('i am in mode menue');
  }

  async SetUserMode(userMode: EmployeeType) {
    this.userService.userMode = userMode;
    this.router.navigate(['/menu']);
    this.parkingService.applyFilterParkingsByUserMode();
  }
}
