<app-modals-header></app-modals-header>
<br />
<div>
  <p>דוח תחזוקה</p>
  <form [formGroup]="_maintenanceForm">
    <div style="border: 2px solid purple">
      <h3 style="font-weight: bold">בחר הכל</h3>
      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-6">
            <input
              [id]="2"
              type="radio"
              name="selectAll"
              [value]="2"
              [(ngModel)]="selectAllVal"
              [ngModelOptions]="{ standalone: true }"
              (change)="selectAll()"
            /><label for="rad1">לא תקין</label>
          </div>
          <div class="col-xs-6">
            <input
              [id]="1"
              type="radio"
              name="selectAll"
              [value]="1"
              [(ngModel)]="selectAllVal"
              [ngModelOptions]="{ standalone: true }"
              (change)="selectAll()"
            /><label for="rad1">תקין</label>
          </div>
        </div>
      </div>
    </div>
    <div
      class="form1"
      formArrayName="Array"
      *ngFor="let arrayItem of _arrayItems; let i = index"
      style="border: 2px solid purple"
    >
      <h4>{{ arrayItem.item }}:</h4>
      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-6">
            <input
              [id]="arrayItem.id"
              type="radio"
              id="rad2"
              [value]="2"
              [(ngModel)]="_arrayItems[i].value"
              [ngModelOptions]="{ standalone: true }"
            /><label for="rad1">לא תקין</label>
          </div>
          <div class="col-xs-6">
            <input
              [id]="arrayItem.id"
              type="radio"
              id="rad1"
              [value]="1"
              [(ngModel)]="_arrayItems[i].value"
              [ngModelOptions]="{ standalone: true }"
            /><label for="rad1">תקין</label>
          </div>
        </div>
      </div>
    </div>
    <div class="center-div" style="margin-top: 10px">
      <textarea
        name="Notes"
        rows="4"
        [(ngModel)]="_maintenanceNotes"
        [ngModelOptions]="{ standalone: true }"
      ></textarea>
    </div>
    <div class="center-div" style="margin-top: 10px">
      <label
        >האם לסיים את ההזמנה
        <input
          type="checkbox"
          [(ngModel)]="_finishOrder"
          [ngModelOptions]="{ standalone: true }"
        />
      </label>
      <button
        type="submit"
        class="blue-btn-class"
        (click)="finishcarMaintenance()"
        style="width: 100%; margin: 0 auto"
      >
        שלח
      </button>
    </div>
  </form>
  <br />
  <br />
  <br />
  <br />
  <br />
</div>
