import { Injectable } from '@angular/core';
import {
  Modal,
  BSModalContext,
  TwoButtonPreset,
} from 'ngx-modialog-7/plugins/bootstrap';
import { DialogRef, overlayConfigFactory } from 'ngx-modialog-7';

@Injectable()
export class ModalService {
  constructor(public modal: Modal) {}

  alert(title: string, text: string) {
    // this.modal
    //   .alert()
    //   .size('lg')
    //   .showClose(true)
    //   .title(title)
    //   .body(`<div><p>` + text + `</p></div>`)
    //   .open();
  }

  openComponentModal(modalContext: BSModalContext, component: any) {
    return this.modal.open(component, overlayConfigFactory(modalContext));
  }

  confirm(
    title: string,
    text: string,
    okButton: string,
    cancelButton: string,
    ok: Function,
  ) {
    const dialogRef: DialogRef<TwoButtonPreset> = this.modal
      .confirm()
      .size('lg')
      .showClose(true)
      .okBtn(null)
      .okBtnClass('hidden')
      .addButton('btn btn-primary', okButton, function (dialogFooter) {
        ok();
        dialogFooter.dialog.close();
      })

      .cancelBtn(null)
      .cancelBtnClass('hidden')
      .title(title)
      .body(`<div><p>` + text + `</p></div>`)
      .open();
    dialogRef.result.then(() => {
      return false;
    });
  }

  confirm2btn(
    title: string,
    text: string,
    okButton: string,
    cancelButton: string,
    ok: Function,
    cancel: Function,
    showClose: boolean = true
  ): boolean {
    let ret;
    this.modal
      .confirm()
      .size('lg')
      .showClose(showClose)
      .okBtn(null)
      .okBtnClass('hidden')
      .addButton('btn btn-primary', okButton, function (dialogFooter) {
        ok();
        ret = true;
        dialogFooter.dialog.close();
      })

      .cancelBtn(null)
      .cancelBtnClass('hidden')
      .addButton('btn btn-primary', cancelButton, function (dialogFooter) {
        cancel();
        dialogFooter.dialog.close();
      })
      .title(title)
      .body(`<div><p>` + text + `</p></div>`)
      .open();
    return false;
  }

}
