import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthState } from '../../enums';
import * as moment from 'moment';
import { Uploads } from '../../models/uploads';
import { UploadsService } from '../../services/uploads.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import PageTitle from '../../models/page-title';
import { ErrorResponse } from '../../models/error-response';
import { ParkingOnMap } from '../../models/parking';
import { User, UsersPreference } from '../../models/user';
import { AlertService } from '../../services/alert.service';
import { GoogleAuthService } from '../../services/google-auth.service';
import { ModalService } from '../../services/modal.service';
import { ParkingsService } from '../../services/parkings.service';
import { UserService } from '../../services/user.service';
import { AppGlobals } from '../../shared/app-globals/app-globals';

export class Check {
  front: boolean;
  back: boolean;
  selfie: boolean;
  checkDateNow: boolean;
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit {
  pageTitleUserProfile: PageTitle = {
    title: 'PERSONAL_AREA',
    previousUrl: '/menu',
  };

  get pageTitle() {
    return this.pageTitleUserProfile;
  }

  public userForm: FormGroup;
  user: User;
  selectedTab: number;
  uploads: Uploads = new Uploads();
  check: Check = new Check();
  selectedParkings: ParkingOnMap[] = new Array<ParkingOnMap>();
  preferncedParkings: UsersPreference;
  alreadyHasPreference: boolean;
  errMessage: string = null;

  get maxImagesSize(): number {
    return AppGlobals.IMAGES_DATA.MAX_SIZE_KB.REGISTER;
  }

  constructor(
    public _userService: UserService,
    private _alertService: AlertService,
    private _modalService: ModalService,
    private _googleAuthService: GoogleAuthService,
    private _uploadsService: UploadsService,
    private _parkingsServide: ParkingsService,
    private _router: Router,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    const isUpdateLicense = localStorage.getItem('updateLicense');
    if (isUpdateLicense) {
      localStorage.removeItem('updateLicense');
      this.driverLicense();
    }
  }
  damageReport(): void {
    this._router.navigate(['/damageReport']);
  }

  userDetails() {
    this.pageTitle.title = 'USER_DETAILS';
    this.pageTitle.previousUrl = '/userProfile';
    this.selectedTab = 0;
    console.log(this.pageTitle);
  }

  driverLicense() {
    this.pageTitle.title = 'UPDATE_LICENSE';
    this.pageTitle.previousUrl = '/userProfile';
    this.selectedTab = 1;
  }
  mail() {
    this.pageTitle.title = 'MAIL';
    this.pageTitle.previousUrl = '/userProfile';
    this.selectedTab = 2;
  }

  preferences() {
    this.pageTitle.title = 'PREFERENCES';
    this.pageTitle.previousUrl = '/userProfile';
    this.selectedTab = 3;
    this._userService.getPreferences().subscribe(
      () => {
        this.preferncedParkings = this._userService.usersPreference;
        this.alreadyHasPreference =
          this._userService.usersPreference.parkings.length > 0 ? true : false;
        this.selectedParkings = this.parkings.filter((p) =>
          this.preferncedParkings.parkings.includes(p.id)
        );
        console.log(this.preferncedParkings);
      },
      (err) => {
        console.log(err.CustomErrorMessage);
        this.errMessage = 'לא מוגדר לך תחנות מועדפות';
      }
    );
  }
  sendImage() {
    this.uploads.firstName = this.user.firstName;
    this.uploads.lastName = this.user.lastName;
    this.uploads.id = this._userService.user.identifier;
    this.uploads.status = 0;
    this.uploads.comment = 'הבקשה בטיפול';
    console.log(moment(Date.now()).format('DD/MM/YY HH:mm'));
    this.uploads.dateAction = moment(Date.now()).format('DD/MM/YY HH:mm');

    if (localStorage.getItem(AppGlobals.LOCAL_STORAGE.AuthState) == '2') {
      this._alertService.error('משתמש לא מחובר');
    } else if (this.check.checkDateNow) {
      this._alertService.error('רישיון הנהיגה אינו בתוקף');
    } else if (
      !this.uploads.driverLicenseBack &&
      !this.uploads.driverLicenseFront &&
      !this.uploads.driverLicenseSelfie &&
      !this.uploads.visa
    ) {
      this._alertService.error('לא נמצאו תמונות לשליחה - אנא נסה שנית');
    } else {
      this._uploadsService.sendUploads(this.uploads).subscribe(
        (r) => {
          console.log(r);
          this._alertService.success('התמונות נשמרו בהצלחה');
          setTimeout(() => {
            this._router.navigate(['/menu']);
          }, 2500);
        },
        (err) => {
          this._alertService.error('התמונות לא נשמרו - נסה שנית');
          console.log(err);
        }
      );
    }
  }
  get checkDateNow(): boolean {
    const now = new Date(Date.now());
    const ExpirationDate = new Date(this.uploads.expirationDate);

    if (now > ExpirationDate) {
      this.check.checkDateNow = true;
      return this.check.checkDateNow;
    } else {
      this.check.checkDateNow = false;
      return this.check.checkDateNow;
    }
  }

  get DateNow(): string {
    const now = new Date(Date.now());
    return this.datePipe.transform(now, 'yyyy-MM');
  }

  updateUserDetails(): void {
    this._userService.updateUserDetails(this.user).subscribe(
      (user: User) => {
        this.user = Object.assign({}, user);
        this._alertService.success('הפרטים עודכנו בהצלחה!');
      },
      (error: ErrorResponse) => {
        console.error(error);
        this._alertService.error(error.CustomErrorMessage);
      }
    );
  }

  get driverLicenseExpDate() {
    return moment(this.user.driverLicenseExpDate).format('DD/MM/YYYY');
  }

  logout() {
    localStorage.setItem('AuthState', AuthState.NOT_SET.toString());
    this._googleAuthService.logout();
  }

  update() {
    this._modalService.confirm2btn(
      'עדכון אשראי',
      'הנך עובר כעת למסך ההרשמה כדי לעדכן את פרטי אשראי. אין לשנות פרטים נוספים למעט את פרטי האשראי ',
      'אישור',
      'ביטול',
      () => {
        this._userService.isUpdate = true;
        this._userService.isCreditUpdate = true;
        this._userService.creditUpdateFromUserProfile = true;
        this._router.navigate(['/register']);
      },
      () => {}
    );
  }

  get parkings() {
    return this._parkingsServide.parkings;
  }

  savePrefernces() {
    console.log(this.selectedParkings);
    console.log(this.alreadyHasPreference);
    if (this.selectedParkings.length == 0) {
      console.log('delete');
      this._modalService.confirm2btn(
        'מחיקת העדפות',
        'האם אתה בטוח שברצונך למחוק את ההעדפות?',
        'אישור',
        'ביטול',
        () => {
          this._userService.deletePreference().subscribe(
            (result: { deleted: boolean }) => {
              if (result.deleted == true) {
                this._alertService.success('ההעדפות נמחקו בהצלחה');
                this.preferncedParkings.parkings = [];
              }
            },
            (err) => {
              this._alertService.error('אירעה שגיאה! נסה שנית מאוחר יותר');
            }
          );
        },
        () => {}
      );
    } else if (!this.alreadyHasPreference) {
      console.log('add');
      this._userService.addPreference(this.selectedParkings).subscribe(
        (result: { saved: boolean }) => {
          if (result.saved == true) {
            this._alertService.success('ההעדפות נשמרו בהצלחה');
          }
        },
        (err) => {
          this._alertService.error('אירעה שגיאה! נסה שנית מאוחר יותר');
        }
      );
    } else {
      console.log('update');
      this._userService.updatePreference(this.selectedParkings).subscribe(
        (result: { saved: boolean }) => {
          if (result.saved == true) {
            this._alertService.success('ההעדפות עודכנו בהצלחה');
          }
        },
        () => {
          this._alertService.error('אירעה שגיאה! נסה שנית מאוחר יותר');
        }
      );
    }
  }
}
