import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import PageTitle from '../../models/page-title';
import { ModalService } from '../../services/modal.service';
import { AppGlobals } from '../../shared/app-globals/app-globals';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  get pageTitle() {
    const PageTitle = <PageTitle>{
      title: 'CONTACT_US',
      previousUrl: '/menu',
    };
    return PageTitle;
  }

  get CONTACT_DETAILS() {
    return AppGlobals.CONTACT_DETAILS;
  }
  constructor(
    private translate: TranslateService,
    private _modalService: ModalService,
    private _router: Router
  ) {}

  title: string;
  text: string;
  okBtn: string;
  cancelBtn: string;
  ngOnInit() {
    console.log(this.translate.currentLang);
    this.title =
      this.translate.currentLang == 'he'
        ? 'האם הפניה שלך היא בקשר להזמנה מסויימת?'
        : 'Is your application related to a specific order?';
    this.text =
      this.translate.currentLang == 'he'
        ? "במידה ותאשר, תועבר לרשימת ההזמנות שלך, בחר/י בהזמנה המסויימת שתרצה לשלוח פניה עליה ולחץ/י שם על 'שלח פניה'"
        : 'If you accept, you will be navigated to your order list page, select the specific order you would like to send an email to, and click "Send Email"';
    this.okBtn = this.translate.currentLang == 'he' ? 'כן' : 'Yes';
    this.cancelBtn = this.translate.currentLang == 'he' ? 'לא' : 'No';
  }

  sendMail() {
    console.log(this.translate.currentLang);
    this.title =
      this.translate.currentLang == 'he'
        ? 'האם הפניה שלך היא בקשר להזמנה מסויימת?'
        : 'Is your application related to a specific order?';
    this.text =
      this.translate.currentLang == 'he'
        ? "במידה ותאשר, תועבר לרשימת ההזמנות שלך, בחר/י בהזמנה המסויימת שתרצה לשלוח פניה עליה ולחץ/י שם על 'שלח פניה'"
        : 'If you accept, you will be navigated to your order list page, select the specific order you would like to send an email to, and click "Send Email"';
    this.okBtn = this.translate.currentLang == 'he' ? 'כן' : 'Yes';
    this.cancelBtn = this.translate.currentLang == 'he' ? 'לא' : 'No';
    this._modalService.confirm2btn(
      this.title,
      this.text,
      this.okBtn,
      this.cancelBtn,
      () => {
        this._router.navigate(['/orderList']);
      },
      () => {
        console.log('send mail');
        const mail = document.createElement('a');
        mail.href =
          'mailto:' + this.CONTACT_DETAILS.MAIL + '?subject=שירות לקוחות';
        mail.click();
      }
    );
  }
}
