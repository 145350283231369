import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { ModalService } from '../../services/modal.service';
import { OrderService } from '../../services/order.service';
import { UserService } from '../../services/user.service';
import { Order } from '../../models/order';
import { AppGlobals } from '../app-globals/app-globals';
import { ErrorResponse } from '../../models/error-response';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css'],
})
export class OrderDetailsComponent implements OnInit, AfterViewInit {
  @Input()
  order: Order;
  orderDate: Date;

  get PeriodName(): string {
    return AppGlobals.PERIOD_NAMES[+this.order.period];
  }

  get isOrderForNow(): boolean {
    return this._orderService.isOrderForNow;
  }

  get orderTimeAmount(): number {
    return Math.ceil((this.order.timeAmount * 100) / 100);
  }

  get isUserWantInsurance(): boolean {
    return this._userSerivce.user.isWantInsurance;
  }
  isShow = true;

  showDetails() {
    console.log(this.isShow);
    if (this.isShow) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  }

  onChange(value: any) {
    if (value == true) this._orderService.Order.isInsuranceRequired = true;

    if (value == false) this._orderService.Order.isInsuranceRequired = false;
  }

  constructor(
    private _userSerivce: UserService,
    public _orderService: OrderService,
    private _alertService: AlertService,
    private _modalService: ModalService
  ) {}

  ngOnInit() {
    console.log(this.order);

    this.order.isInsuranceRequired = this._userSerivce.user.isWantInsurance;
    this.order.isInsuranceRequired
      ? (this._orderService.isShowPrice = true)
      : (this._orderService.isShowPrice = false);
    this.isInsuranceRequiredFalse();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.isShabat(this.order)) {
        this._modalService.alert(
          'הודעה חשובה',
          'סיטי קאר הינה חברה שומרת שבת, ועל כן חל איסור מוחלט לנסוע ברכב בשבתות וחגים.  נסיעה ברכב לאחר כניסת השבת/חג עלולה לגרור קנסות כבדים'
        );
      }
    }, 100);
  }

  isShabat(order: Order): boolean {
    let orderDate = new Date(order.startTime);

    while (orderDate <= order.endTime) {
      orderDate = new Date(orderDate.setDate(orderDate.getDate() + 1));
      if (orderDate.getDay() == 0) {
        return true;
      }
    }
    return false;
  }

  calculateOrderPrice() {
    this._orderService.calculateOrderPrice().subscribe(
      (price: number) => {
        this.order.finalPrice = price;
        this._orderService.isShowPrice = true;
      },
      (error: ErrorResponse) => {
        if (error.CustomErrorMessage && error.CustomErrorMessage.length) {
          this._alertService.error(error.CustomErrorMessage);
        }
      }
    );
  }
  isInsuranceRequiredFalse() {
    if (this.order.isInsuranceRequired === false) {
      this.order.isInsuranceRequired = undefined;
    }
  }
}
