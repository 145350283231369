import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { from } from 'rxjs/observable/from';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from './url.service';
import { DamageReport } from '../models/damage-report';
import { AppGlobals } from '../shared/app-globals/app-globals';
import { HttpService } from './http.service';
import { forkJoin } from 'rxjs';

@Injectable()
export class MessagesService {
  constructor(
    private translate: TranslateService,
    private _httpService: HttpService,
    private _urlService: UrlService
  ) {}

  damageReport(orderId: number, damageReport: DamageReport): Observable<any> {
    const body = {
      orderId: orderId,
      updateSource: 3,
      notes: damageReport.note,
      picture: damageReport.image,
    };
    return from(
      this._httpService.post(
        this._urlService.baseUrl() + '/orders/DamageAlert?pretty=1',
        body
      )
    );
  }

  damageReports(
    orderId: number,
    damageReports: DamageReport[]
  ): Observable<any> {
    const damagesObs: Observable<any>[] = [];
    damageReports.forEach((dr) => {
      damagesObs.push(this.damageReport(orderId, dr));
    });
    return forkJoin(damagesObs);
  }

  loginMessages(subscriptionStatus) {
    let title;
    let message;
    let btn1;
    let alert = { TITLE: title, MESSAGE: message, BTN1: btn1 };

    switch (subscriptionStatus) {
      case 1:
        alert =
          this.translate.currentLang == 'he'
            ? AppGlobals.MESSAGES.CANCELED.HE
            : AppGlobals.MESSAGES.CANCELED.EN;
        break;
      case 2:
        alert =
          this.translate.currentLang == 'he'
            ? AppGlobals.MESSAGES.BLOCKED.HE
            : AppGlobals.MESSAGES.BLOCKED.EN;
        break;
      case 3:
        alert =
          this.translate.currentLang == 'he'
            ? AppGlobals.MESSAGES.PENDING.HE
            : AppGlobals.MESSAGES.PENDING.EN;
        break;
      case 4:
        alert =
          this.translate.currentLang == 'he'
            ? AppGlobals.MESSAGES.UPDATE.HE
            : AppGlobals.MESSAGES.UPDATE.EN;
        break;
    }

    return alert;
  }
}
