import { Component, Input } from '@angular/core';
import { ValidatorsList } from '../../classes/validators-list';

@Component({
  selector: 'app-validator-messages',
  templateUrl: './validator-messages.component.html',
  styleUrls: ['./validator-messages.component.css'],
})
export class ValidatorMessagesComponent {
  @Input()
  validators: ValidatorsList;

  @Input()
  value: any;

}
