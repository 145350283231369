import {
  Component,
  OnInit,
  AfterViewInit,
  forwardRef,
  Inject,
} from '@angular/core';
import { AppStartService } from './services/app-start.service';
import { AppGlobals } from './shared/app-globals/app-globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { UrlService } from './services/url.service';
import { Modal } from 'ngx-modialog-7/plugins/bootstrap';
import { HttpService } from './services/http.service';
import { LoadingService } from './services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewInit, OnInit {
  title = 'app';
  show = this._appStart.show;
  private _isDev = true;
  ngOnInit(): void {
    this._appStart.loadTranslators();
  }

  public get baseUrl(): string {
    return this._httpService._baseUrl;
  }
  public set baseUrl(v: string) {
    this._httpService._baseUrl = v;
  }

  public get isDev(): boolean {
    return this._isDev;
  }
  public set isDev(v: boolean) {
    if (v) this._httpService._baseUrl = this.urlService.getTestUrl();
    else this._httpService._baseUrl = this.urlService.getTestUrl();
    this._isDev = v;
  }

  constructor(
    @Inject(forwardRef(() => AppStartService))
    public _appStart: AppStartService,
    private _loadingService: LoadingService,
    private _httpService: HttpService,
    private urlService: UrlService,
    public modal: Modal,
    private translate: TranslateService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setTranslator();
    }, 100);
  }

  changeOfRoutes() {
    this._loadingService.stopLoading();
    AppGlobals.PREVIOUS_URL = null;
  }

  openMenu() {
    alert('open menu');
  }

  setTranslator() {
    console.log('set-translator');
    //set defoult language:
    this.translate.setDefaultLang('he');

    const selectedLanguage = localStorage.getItem('language');

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem('language', event.lang);
      if (event.lang == 'he') {
        document.body.dir = 'rtl';
      } else document.body.dir = 'ltr';
    });

    selectedLanguage
      ? this.translate.use(selectedLanguage)
      : this.translate.use('he');
  }
}
