import { Component, OnInit } from '@angular/core';
import { UrlService } from '../../services/url.service';
import { AuthState } from '../../enums';
import { Router } from '@angular/router';
import PageTitle from '../../models/page-title';
import { GoogleAuthService } from '../../services/google-auth.service';
import { HttpService } from '../../services/http.service';
import { UserService } from '../../services/user.service';
import { AppGlobals } from '../../shared/app-globals/app-globals';

declare const window;

export interface StateGroup {
  letter: string;
  names: string[];
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [],
})
export class HomeComponent implements OnInit {
  get pageTitle(): PageTitle {
    return {
      title: 'HOME',
      previousUrl: '',
    };
  }

  try2: Date = new Date();
  appGlobals = AppGlobals;

  public get baseUrl(): string {
    return this._httpService._baseUrl;
  }
  public set baseUrl(v: string) {
    this._httpService._baseUrl = v;
  }

  private _isDev: boolean =
    this._httpService._baseUrl == this._urlService.getTestUrl();
  public get isDev(): boolean {
    return this._isDev;
  }
  public set isDev(v: boolean) {
    if (v) {
      this._httpService._baseUrl = this._urlService.getTestUrl();
      this._urlService.isDev = true;
      localStorage.setItem('isDev', 'true');
    } else {
      localStorage.removeItem('isDev');
      this._urlService.isDev = false;
      this._httpService._baseUrl = this._urlService.getBaseUrl();
    }

    this._isDev = v;
  }

  constructor(
    private _httpService: HttpService,
    private _urlService: UrlService,
    private _router: Router,
    private _googleAuthService: GoogleAuthService,
    private _userService: UserService
  ) { }

  ngOnInit() {
    AppGlobals.IS_AGENTS = false;
    AppGlobals.AGENTS_USER_ID = null;
    this._userService.creditUpdateFromUserProfile = false;

    if (localStorage.getItem('AuthState') == '0') {
      localStorage.setItem('AuthState', AuthState.NOT_SET.toString());
      this._googleAuthService.logout();
    }

    localStorage.setItem('rentInCarAlert', 'true');

    this._urlService.setBaseUrl(true);
    this._httpService._baseUrl = this._urlService.getBaseUrl();
    console.log(this._httpService._baseUrl);
    console.log('AppGlobals.ID: ' + AppGlobals.ID);
    console.log(localStorage.getItem('AuthState'));
  }
}
function openExternal(elem) {
  window.open(elem.href, '_system');
  return false; // Prevent execution of the default onClick handler
}
