<app-page-title [pageTitle]="pageTitle"></app-page-title>
<div id="result">
  <section id="items">
    <div
      style="
        text-align: center;
        margin-top: 20px;
        color: white;
        font-weight: bold;
      "
    >
      <h1>תפריט {{ menueType }}</h1>
    </div>
    <div class="container-fluid" style="margin-top: 30px">
      <div class="row">
        <div class="col-xs-6 text-center" (click)="openNewOrder()">
          <a style="text-decoration: none" id="locations-of-cars">
            <img src="assets/images/rent_icon3.png" alt="מיקום" />
            <h4 translate>ORDER_BY_LOCATION</h4>
          </a>
        </div>
        <div class="col-xs-6 text-center" [routerLink]="['/location']">
          <a style="text-decoration: none" id="locations-of-cars">
            <img src="assets/images/rent_icon7.png" alt="" />
            <h4 translate>ORDER_BY__STATIONS</h4>
          </a>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-xs-6 text-center" [routerLink]="['/orderList']">
          <a style="text-decoration: none" id="booking-details">
            <img src="assets/images/rmp-icon1.png" alt="" />
            <h4 translate>ALL_ORDERS_DETAILS</h4>
          </a>
        </div>
        <div class="col-xs-6 text-center" (click)="showCarsInRent()">
          <a
            style="text-decoration: none"
            data-toggle="modal"
            data-target="#EndRent"
          >
            <img src="assets/images/rmp-icon5.png" alt="" />
            <h4 translate>CURRENT_ACTIVE_ORDER</h4>
          </a>
        </div>
      </div>
    </div>
  </section>
</div>
